import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Button from '../../ui/Button/Button'
import './Services.sass'
import s1 from './s1.jpg'
import s2 from './s2.jpg'
import s3 from './s3.png'

function Services() {
  return (
    <section id="services" className="Services DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Дополнительные услуги" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="Services-Container">
              <ServiceTile
                avatar={s1}
                title="Экскурсии по Кольскому полуострову"
                description="Откроем для Вас абсолютно новый мир цветущей тундры, незаходящего полночного Солнца и невероятный вкусовой спектр Арктической кухни. Покорить Северный Ледовитый океан и познакомиться с китами поможет iTerra."
                link="https://iterrainc.ru/"
              />
              <ServiceTile
                avatar={s2}
                title="Незабываемый отдых в Хибинах"
                description="Мини-отель «Горная долина» расположен вблизи горнолыжного курорта Кукисвумчорр. Территория прилегает к прогулочной зоне с прекрасной панорамой на горы и местные пейзажи. Каждый номер идеально подойдет для комфортного проживания как любителям активного отдыха, так и тем, кто хочет насладиться тишиной и спокойствием предгорной местности.
"
                link="http://gornaya-dolina.info/"
              />
              <ServiceTile
                avatar={s3}
                title="Частичка Севера на память"
                description="Не просто сувениры, а уникальные изделия ручной работы, с душой и качественно изготовленные мастерами Севера. В отеле представлены изделия из натуральной кожи, полимерной глины, эпоксидной смолы, дерева и многое другое.
Поможем подобрать сувенир на любой вкус и кошелек - от небольших магнитов до панно в технике «стринг-арт»."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ServiceTile({ avatar, title, description, link }) {
  return (
    <div className="ServiceTile">
      <h4 className="Title">{title}</h4>
      <p className="Description">{description}</p>
      <img src={avatar} className="Avatar" />
      {link && (
        <Button
          type="link"
          path={link}
          title="Перейти на сайт"
          fill="accent"
          theme="solid"
        />
      )}
    </div>
  )
}

export default Services
