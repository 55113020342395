import React from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import hero from './assets/hero.jpg'
import heroText from './assets/hvoyahotel.svg'
import Button from '../../ui/Button/Button'
import star from './assets/star.png'

function Hero() {
  return (
    <div
      id="home"
      className="Site-Hero"
      style={{
        backgroundImage: `url(${hero})`,
      }}
    >
      <div className="Offer">
        <div className="Stars">
          <span
            className="Star"
            style={{ backgroundImage: `url(${star})` }}
          ></span>
          <span
            className="Star"
            style={{ backgroundImage: `url(${star})` }}
          ></span>
          <span
            className="Star"
            style={{ backgroundImage: `url(${star})` }}
          ></span>
        </div>
        <h1 className="Offer-Title">
          Мини-отель
          <br />
          <span>в атмосфере хвойного леса</span>
        </h1>
        <div className="Button-Container">
          <p className="Title">Забронировать номер online</p>
          <Button
            title="Показать наличие"
            fill="accent2"
            theme="solid"
            type="navlink"
            path="/book"
          />
        </div>
      </div>
      <div
        className="HeroText"
        style={{
          backgroundImage: `url(${heroText})`,
        }}
      ></div>
    </div>
  )
}

export default withRouter(Hero)
