import p1 from './assets/numbers/1.jpg'
import p2 from './assets/numbers/2.jpg'
import p3 from './assets/numbers/3.jpg'
import p4 from './assets/numbers/4.jpg'
import p5 from './assets/numbers/5.jpg'
import p6 from './assets/numbers/6.jpg'
import p7 from './assets/numbers/7.jpg'
import p8 from './assets/numbers/8.jpg'
import p9 from './assets/numbers/9.jpg'

const photos = [p1, p2, p3, p4, p5, p6, p7, p8, p9]
export default photos
