import React from 'react'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './HotelBenefits.sass'

function HotelBenefits() {
  return (
    <section className="HotelBenefits DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Оставайтесь дольше" />
            <div className="HotelBenefits-Container">
              {/* <Benefit
                icon="salad"
                title="Завтрак в подарок"
                desc="Только до 31.07 завтрак в подарок, при бронировании от 3-х суток"
              /> */}
              <Benefit
                icon="shield-check"
                title="Гарантия низкой цены"
                // desc="При заезде от 5-ти человек, скидка на проживание 15%"
              />
              <Benefit
                icon="baby"
                title="Проживание детей до 5 лет бесплатно"
                // desc="Акция действует при бронировании не менее, чем за 7 дней до заезда"
              />
              <Benefit
                icon="briefcase"
                title="Специальные тарифы для командировочных"
                // desc="Акция действует при бронировании не менее, чем за 7 дней до заезда"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Benefit({ title, icon, desc }) {
  return (
    <div className="Benefit">
      <div className="BIcon">
        <Icon name={icon} weight="solid" />
      </div>
      <p className="Title">{title}</p>
      {/* <div className="BContent"> */}
      {/* <p className="Description">{desc}</p> */}
      {/* </div> */}
    </div>
  )
}

export default HotelBenefits
