import React, { useContext } from 'react'
import './About.sass'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import hero from './bg.jpg'
import logo from './hvoya.svg'
import aboutimg from './about.jpg'

function About() {
  return (
    <section
      id="about"
      className="About DefaultSection"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <AboutContent
              description={
                <>
                  <p>
                    Мини-отель «Хвоя» расположен в центре города Мурманск. Для
                    гостей мы предлагаем 8 комфортабельных номеров в
                    скандинавском стиле. В нашем небольшом отеле мы поддерживаем
                    атмосферу спокойствия, уединения и радушия, чтобы Вы могли
                    полностью расслабиться после тяжелого рабочего дня или
                    настроиться на увлекательную экскурсию по Мурманской
                    области. Администраторы отеля круглосуточно готовы помочь
                    забронировать экскурсию, заказать столик в ресторане или
                    просто поддержать беседу. Скрасить Ваше пребывание поможет
                    согревающий авторский чай и настольные игры в лобби нашего
                    отеля или захватывающий фильм из подписки Кинопоиск.
                    <br /> <br />
                    <b>Мы ждем Вас всегда!</b>
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const AboutContent = ({ title = 'hvoya', description }) => {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="About-Container">
      <div className="About-Info">
        <img src={logo} alt="" className="LogoAbout" />
        <div className="Description">{description}</div>
        {/* <div className="Buttons">
          <Button
            title="Заказать обратный звонок"
            fill="accent2"
            theme="solid"
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Заказать обратный звонок',
                emailSubject: 'Заказ обратного звонка',
              })
            }
          />
        </div> */}
      </div>
      <div className="Avatar">
        <img src={aboutimg} />
      </div>
    </div>
  )
}

export default About
