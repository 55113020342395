import React from 'react'
import './LegacyInfo.sass'

function LegacyInfo() {
  return (
    <div className="LegacyInfo">
      <div className="container">
        <div className="row">
          <div className="col-12 LegacyInfo-Container">
            <a href="/docs/policy.pdf" target="blank">
              Политика конфиденциальности
            </a>
            ,{' '}
            <a href="/docs/sogl.pdf" target="blank">
              Согласие на обработку персональных данных
            </a>
            {/*
            <p style={{ marginTop: '12px' }}>
              Персональные данные на сайте размещены с согласия, условия запрета
              не установлены
            </p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegacyInfo
