import React from 'react'
import { useEffect } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import ContactUs from '../ContactUs/ContactUs'
import './Book.sass'
import LegacyInfo from '../LegacyInfo/LegacyInfo'

function Book() {
  useEffect(() => {
    // var BookingIframe = new window.BookingIframe({
    //   html_id: 'booking_iframe',
    //   uid: '6722a8d2-9804-4570-b3cb-a19cf2c918dc',
    //   lang: 'ru',
    //   width: 'auto',
    //   height: 'auto',
    //   rooms: '',
    //   scroll_to_rooms: '1',
    // })
    // BookingIframe.init()
  }, [])
  return (
    <>
      <section className="Book DefaultSection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle title="Забронировать номер" />
              <div id="tl-booking-form">&nbsp;</div>

              {/* <div class="left" id="_bn_widget_">
                <a
                  href="http://bnovo.ru/"
                  id="_bnovo_link_"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bnovo
                </a>
              </div> */}

              {/* <div
                id="booking_iframe"
                style={{
                  paddingBottom: '32px',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <div
                  id="bn_iframe"
                  style={{
                    position: 'absolute',
                    right: '0',
                    marginTop: '12px',
                    fontFamily: 'Proxima nova,  sans-serif',
                    fontSize: '12px',
                    lineHeight: '1em',
                    opacity: '0.5',
                    zIndex: '3',
                    bottom: '0',
                  }}
                >
                  <div
                    style={{
                      color: '#1403FC !important',
                      background: 'rgba(0, 0, 0, 0) !important',
                    }}
                  >
                    <a
                      style={{
                        color: '#808080 !important',
                        background: '#ffffff !important',
                      }}
                      href="http://bnovo.ru/"
                      id="bnovo_link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Система управления отелем Bnovo ©
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <ContactUs />
      <LegacyInfo />
    </>
  )
}

export default Book
