import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Numbers.sass'
import b1 from './assets/b1.svg'
import b2 from './assets/b2.svg'
import parse from 'html-react-parser'
import Media from 'react-media'
import Carousel from '../../ui/Carousel/Carousel'
import photos from './photos'

function Numbers() {
  const slider = (
    <Carousel
      element="NumbersCarousel"
      options={{
        type: 'carousel',
        perView: 1,
        autoplay: 5000,
        useDots: true,
        useArrows: true,
      }}
    >
      {photos.map((src) => (
        <a data-fancybox data-src={src}>
          <img key={src} src={src} />
        </a>
      ))}
    </Carousel>
  )

  return (
    <section id="numbers" className="Numbers DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Numbers-Container">
              <Media query="(min-width: 1025px)" render={() => slider} />
              <div className="Info">
                <SectionTitle title="Наши номера" />
                <p className="Desc">
                  Просторные однокомнатные номера в скандинавском стиле
                  рассчитаны на размещение от 1 до 4 гостей. В каждом номере Вас
                  ожидает: ванная комната, телевизор со СМАРТ-TV, рабочее место,
                  чайная станция, система очистки воздуха, сейф, косметические
                  принадлежности, фен и многое другое для комфортного
                  проживания. Круглосуточно угощаем листовым чаем и
                  свежесваренным кофе.
                </p>
                <Media query="(max-width: 1024px)" render={() => slider} />
                <div className="Numbers-Data">
                  <NumberInfo
                    counter={5}
                    ctitle="номеров"
                    title="Двухместный стандарт"
                    options={[
                      {
                        icon: b2,
                        title: '2 одноместные кровати',
                        desc: '900 х 2000',
                      },
                      {
                        icon: b1,
                        title: '1 большая кровать',
                        desc: '2000 х 2000',
                      },
                    ]}
                  />
                  <NumberInfo
                    counter={3}
                    ctitle="номера"
                    title="Трехместный стандарт"
                    options={[
                      {
                        icon: b2,
                        title: '3 одноместные кровати',
                        desc: '900 х 2000',
                      },
                      {
                        icon: b1,
                        title: '1 большая кровать<br/>2000 х 2000',
                        desc: '1 одноместная кровать<br/>900 х 2000',
                      },
                    ]}
                  />
                </div>
                <p>
                  * Для гостей с детьми предоставляем детскую кроватку, коляску,
                  столик для пеленания, стульчик для кормления и детские
                  средства личной гигиены
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function NumberInfo({ counter, ctitle, title, options }) {
  return (
    <div className="NumberInfo">
      <p className="Counter">
        {counter} <span className="cTitle">{ctitle}</span>
      </p>
      <p className="Title">{title}</p>
      {options.map((o) => (
        <div className="OptionContainer">
          <img src={o.icon} className="oIcon" />
          <div className="oInfo">
            <p className="oTitle">{parse(o.title)}</p>
            <p className="oDesc">{parse(o.desc)}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Numbers
