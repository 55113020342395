import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyB97hyVwYwlRgxs10c1iUFJb9oTmNHhGXQ',
  authDomain: 'hvoya-e4892.firebaseapp.com',
  projectId: 'hvoya-e4892',
  storageBucket: 'hvoya-e4892.appspot.com',
  messagingSenderId: '946863912644',
  appId: '1:946863912644:web:13c6eafae0c58256747b91',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
