import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Food.sass'
import foodImg from './food.jpg'
import Carousel from '../../ui/Carousel/Carousel'
import f1 from './f1.jpg'
import f2 from './f2.jpg'
import f3 from './f3.jpg'
import f4 from './f4.jpg'
import f5 from './f5.jpg'

function Food() {
  const photos = [f1, f3, f4, f2, f5]
  const slider = (
    <Carousel
      element="FoodCarousel"
      options={{
        type: 'carousel',
        perView: 1,
        autoplay: 5000,
        useDots: true,
        useArrows: true,
      }}
    >
      {photos.map((src) => (
        <a data-fancybox data-src={src}>
          <img key={src} src={src} />
        </a>
      ))}
    </Carousel>
  )

  return (
    <section className="Food DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Вкусное меню" />
            <div className="Food-Container">
              <div className="Info">
                <p className="Title">Завтрак</p>
                <p>
                  Для гостей отеля, ежедневно с 07:00 до 10:00, мы готовим
                  сытный завтрак «Континентального типа»
                </p>
                <p className="Bolder">Типовое меню</p>
                <ul>
                  <li>Сырная и колбасная нарезка</li>
                  <li>Нарезка из свежих овощей</li>
                  <li>Йогурты</li>
                  <li>Каши на молоке</li>
                  <li>Блины/Сосиски</li>
                  <li>Сухие завтраки и мюсли</li>
                  <li>Фрукты</li>
                  <li>Джем и сливочное масло</li>
                  <li>Хлеб и тосты</li>
                </ul>
                <p className="Title">Вечерний перекус</p>
                <p>
                  Особая услуга в нашем уютном островке безмятежного отдыха для
                  гостей, которые, как и мы, тоже частенько любят вкусно
                  перекусить после 6
                </p>
                <p className="Bolder">По пункту на выбор</p>
                <ul>
                  <li>Напиток: Ряженка; Кефир; Йогурт питьевой</li>
                  <li>
                    Закуска: рисовый хлебец с творожным сыром, рукколой и рыбой;
                    круассан с ветчиной и сыром; шоколадный маффин
                  </li>
                  <li>Фрукт: Яблоко, Банан, Мандарин</li>
                </ul>
              </div>
              <div className="Food-Img">{slider}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Food
